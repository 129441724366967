<template>
  <main id="editNote" class="screen justify-content-start" v-if="show">
    <header class="flat">
      <h1 v-if="this.context === 'add'">
        Add Note
        <small class="faded">{{
          $dayjs(date).format("DD MMM YYYY hh:mma")
        }}</small>
      </h1>
      <h1 v-else>
        Edit Note
        <small class="faded">{{
          $dayjs(date).format("DD MMM YYYY hh:mma")
        }}</small>
      </h1>
      <button @click="close()" class="btn">
        <img src="/icons/close.svg" alt="" />
      </button>
    </header>
    <section class="content pt-2 pb-5">
      <form
        class="formWrapper rounded"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <fieldset>
          <!-- <label>Customer Notes</label> -->
          <textarea
            v-model="noteText"
            cols="30"
            rows="7"
            class="form-control"
            placeholder=""
          ></textarea>
        </fieldset>
      </form>
    </section>
    <footer class="px-2 row">
      <div class="col-12">
        <button @click="save" class="btn btn-block btn-primary">
          SAVE NOTE
        </button>
      </div>
    </footer>
  </main>
</template>

<script>
export default {
  created() {
    this.$nuxt.$on("addNote", (previousNote, context) => {
      this.show = true;
      this.context = context;
      this.noteText = previousNote;
    });
  },
  methods: {
    save() {
      this.show = false;
      this.$nuxt.$emit("changeNote", this.noteText, this.context);
      this.noteText = "";
    },

    // deleteNote:function(){
    //   alertify.confirm('Delete Note', 'Are you sure you want to delete this note?',
    //             () => {
    //               this.context.data = this.note
    //               this.$nuxt.$emit('deleteNote',this.context)
    //               this.show = false
    //               alertify.notify('Note Deleted','success')
    //             }
    //           , function(){});
    // },

    close: function() {
      this.show = false;
      this.noteText = "";
    }
  },
  data() {
    return {
      show: false,
      date: new Date(),
      noteText: "",
      context: "add"
    };
  }
};
</script>
