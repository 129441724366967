import Vue from 'vue'
import axios from 'axios'
var VueScrollTo = require('vue-scrollto');

Vue.mixin({
  methods: {
    scoreColouredText:function(score){
      if(score <= 33) return 'text-danger'
      if(score > 33 && score <= 66) return 'text-partially'
      if(score > 66) return 'text-primary'
    },
    dateStringToFormatted:function(dateString, format = 'DD MMM YYYY'){
      this.dateItem = this.$moment(dateString);
      return this.dateItem.format(format);
    },
    returnDateInUTC(date){
      return this.$moment.utc(date)
    },
    returnTime(dateString){
      this.dateItem = this.$moment(dateString);
      return this.dateItem.format('HH:mma');
    },
    timeDiff(startDate, endDate){
      let momentStart = this.$moment(startDate)
      let momentEnd = this.$moment(endDate)
      return momentEnd.diff(momentStart, 'minutes')
    },
    hourDiff(startDate, endDate){
      let momentStart = this.$moment(startDate)
      let momentEnd = this.$moment(endDate)
      return momentEnd.diff(momentStart, 'hours')
    },
    dayOfWeek(dateItem,format){
      //Create an array containing each day, starting with Sunday.
      if(format && format == 'long'){
        var weekdays = new Array(
          "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday")
      }else{
        var weekdays = new Array(
          "Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat")
      }
      //Use the getDay() method to get the day.
      dateItem = new Date(dateItem)
      var day = dateItem.getDay();
      //Return the element that corresponds to that index.
      return weekdays[day];
    },

    timeRange(dateItem,duration){
      //Create an array containing each day, starting with Sunday.
      dateItem = new Date(dateItem)
      var newDateObj = new Date(dateItem.getTime() + duration*60000);

      //Return the element that corresponds to that index.
      return dateItem.getHours()+":"+ (dateItem.getMinutes() == 0 ? "00" : dateItem.getMinutes()) + " - "+ newDateObj.getHours()+":"+(newDateObj.getMinutes() == 0 ? "00" : newDateObj.getMinutes());
    },

    closeActionPageMixin:function(){
      this.$store.commit('loadActionPage', '');
    },
    openActionPageMixin:function(pageID,pageData){
    	this.$store.commit('loadActionPage', {pageID:pageID , pageData:pageData});
    },

    // Initialise Location Detection
    verifyGeoLocation(){
      let options = {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000
      }
      navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError, options);
    },

      // Can get Location - Check Distance from Contract
      locationSuccess(position){
        let contractLat = this.contract.latitude
        let contractLong = this.contract.longitude
        let positionLat = position.coords.latitude
        let positionLong = position.coords.longitude

        // Skip the proximity check if in dev mode
        if(process.env.mode.trim() == 'development'){
          alertify.success(`CURRENT POSITION - Latitude: ${position.coords.latitude} - Longitude: ${position.coords.longitude}`)
          this.$router.push(`/inspections/${this.$route.params.id}`)
          return
        }

        // Proximity check - if distance is greater than 200m
        let distanceInMeters = this.getDistanceFromLatLonInMeters(contractLat, contractLong, positionLat, positionLong)
        if(distanceInMeters > 20000000){
          // alertify.error('Error starting Inspection ${Math.round(distanceInMeters)}m away from the contract location. You need to be closer in order to do an inspection.')
          alertify.notify('Error starting Inspection. '+Math.round(distanceInMeters)+'m away.','error',10000)
          return
        }

        this.$router.push(`/inspections/${this.$route.params.id}`)
      },
        getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2){
            let R = 6371;
            let dLat = this.deg2rad(lat2-lat1);
            let dLon = this.deg2rad(lon2-lon1);
            let a =
              Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
              Math.sin(dLon/2) * Math.sin(dLon/2);

            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            let d = R * c; // Distance in km
            return d * 1000;
        },
        deg2rad(deg){
          return deg * (Math.PI/180)
        },

      locationError(err){
        alertify.alert("We are having trouble getting your location. Please make sure your location services are on and that the app has permission to get your location.")
        console.error(err)
      },

      workedDuration(startDate, endDate){
        let momentStart = this.$moment(startDate)
        let momentEnd = this.$moment(endDate)
        let diff = momentEnd.diff(momentStart, 'minutes')
        if(diff > 420){
          // Remove Lunch break if over 7hrs
          return { hrs: Math.round((diff-60)/6)/10, lunch: 1 }
        }else{
          return { hrs: Math.round(diff/6)/10, lunch: 0 }
        }
      },


      isLocalDev(){
        if(window.location.host.split(':')[0] == "localhost"){
          return true
        }        
        return false
      },


      getRequestTypeTitle(request) {
        return request.request_type.slug.replace(/-/g, ' / ').replace(/\b\w/g, l => l.toUpperCase())
      },
  
      getRequestStepTitle(request){
        if(request){
            return request.request_type.settings.process[request.status].step
        }else{
            return ''
        }
            
    },


  }
})
