<template>
  <div class="app">
    <versions></versions>
    <notifications></notifications>
    <!-- <Loader ></Loader> -->
    <nuxt />
    <transition name="slide-over" mode=""><scanActionPage class="overScreen" v-if="activeModel == 'scan'"></scanActionPage></transition>
    <transition name="slide-over" mode=""><noteActionPage class="overScreen"></noteActionPage></transition>
    <transition name="slide-over" mode=""><postponeActionPage class="overScreen" v-if="activeModel == 'postpone'"></postponeActionPage></transition>
    <transition name="fade" mode=""><signActionPage class="overScreen" v-if="activeModel == 'sign'"></signActionPage></transition>
    <Navbar></Navbar>
  </div>
</template>

<script>
import Versions from '~/components/versions.vue'
import Navbar from "~/components/navbar.vue";
import Notifications from '~/components/notifications.vue'
import scanActionPage from '~/components/popovers/scan.vue';
import noteActionPage from '~/components/popovers/note.vue';
import postponeActionPage from '~/components/popovers/postpone.vue';
import signActionPage from '~/components/popovers/sign.vue';
import Loader from "~/components/items/loader.vue";

export default {
  components: {
    scanActionPage,
    noteActionPage,
    postponeActionPage,
    signActionPage,
    Notifications,
    Navbar,
    Loader,
    Versions
  },
  watch: {
    async $route(to, from) {
      // Dont refresh if user is busy with inspections
      if(to.path.includes("inspections") || from.path.includes("inspections")) return
      let storedUnixDate = localStorage.getItem("refreshDate")

      // Set Date if none is stored
      if(!storedUnixDate) {
        this.setUnixDate()
        return
      }

      let newUnixDate = new Date().getTime()
      const SECONDS = 3600 // 1 hour
      
      // Compare time and if its greater than 1 hour refresh app
      let remainderTime = newUnixDate - storedUnixDate
      if(remainderTime > (SECONDS * 1000)){
        window.localStorage.removeItem("refreshDate");
        location.reload()
      }
    },
  },
  head () {
    return {
      title: "ECS App",
      meta: [
        { hid: 'description', name: 'description', content: '' }
      ],
    }
  },
  mounted(){

  },
  computed:{
    activeModel:function(){
      return this.$store.state.ui.activeModel
    },
  },
  methods:{
    setUnixDate(){
      window.localStorage.setItem("refreshDate", new Date().getTime())
    }
  }
}
</script>
