<template>
</template>

<script>
export default {
  computed: {
    baseUrl: function () {
      return process.env.baseUrl
    },
    env: function () {
      return process.env.NODE_ENV
    },
    version: function () {
      return this.$store.getters["getVersion"];
    },
    apiVersion: function () {
      return this.$store.state.apiVersion;
    }
  },
  watch: {
    version: function (newVersion, oldVersion) {
      if (oldVersion !== newVersion) {
        this.output();
      }
    },
    apiVersion: function (newApiVersion, oldApiVersion) {
      if (oldApiVersion !== newApiVersion) {
        this.output();
      }
    },
  },
  mounted() {
    this.getApiVersion();
  },
  methods: {
    async getApiVersion() {
      await this.$store.dispatch('fetchApiVersion');
    },
    output() {
      console.log('App Env:', this.env);
      console.log('App Version:', this.version);
      console.log('Api Version:', this.apiVersion);
      // console.log('Api Url:', this.baseUrl);
    }
  },
}
</script>
