<template>
  <main id="postponeInspection" class="screen justify-content-start" v-if="contract">
    <header class="flat">
      <h1>
        Postpone Inspection
        <small class="faded">{{ dateToday }} • {{ contract.company }}</small>
      </h1>
      <button @click="closeActionPageMixin()" class="btn">
        <img src="/icons/close.svg" alt="" />
      </button>
    </header>
    <section class="content py-3">
      <ValidationObserver v-slot="{ handleSubmit }" ref="orderForm" tag="div">
        <form class="row" @submit.prevent="handleSubmit(onSubmit)">
          <div class="col-12">
            <ValidationProvider
              name="reason"
              rules="required"
              v-slot="{ errors, classes }"
              tag="div"
              class="form-group mb-4"
            >
              <label>Reason</label>
              <select
                class="form-control form-control-sm"
                id="reason"
                v-model="postpone.reason"
                :class="classes"
              >
                <option>Client not available</option>
                <option>Previous meeting over time</option>
                <option>Delayed</option>
              </select>
              <span v-if="errors.length" class="help-block form-error">{{
                errors[0]
              }}</span>
            </ValidationProvider>

            <h6>Reschedule inspection<sup>*</sup></h6>
            <ValidationProvider
              name="reason"
              rules="required"
              v-slot="{ errors, classes }"
              tag="div"
              class="form-group"
            >
              <ValidationProvider
                name="date"
                rules="required"
                v-slot="{ errors, classes }"
                tag="div"
                class="input-group"
              >
                <input
                  type="date"
                  id="date"
                  v-model="postpone.date"
                  :class="classes"
                  class="form-control"
                />
              </ValidationProvider>
              <span v-if="errors.length" class="help-block form-error">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <ValidationProvider
              name="reason"
              rules="required"
              v-slot="{ errors, classes }"
              tag="div"
              class="form-group mt-1"
            >
              <ValidationProvider
                name="date"
                rules="required"
                v-slot="{ errors, classes }"
                tag="div"
                class="input-group"
              >
                <input
                  type="time"
                  id="time"
                  v-model="postpone.time"
                  :class="classes"
                  class="form-control"
                />
              </ValidationProvider>
              <span v-if="errors.length" class="help-block form-error">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </form>
      </ValidationObserver>
    </section>
    <footer class="px-2">
      <button
        tag="button"
        @click="reSchedule()"
        class="btn btn-block btn-primary"
      >
        RESCHEDULE
      </button>
    </footer>
  </main>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  configure
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    contract() {
      return this.$store.state.contractDetail;
    },
    schedule() {
      if (this.contract !== null && this.contract.inspections !== undefined && this.contract.inspections.scheduled_inspection !== undefined) {
        return this.contract.inspections.scheduled_inspection;
      }
      return null;
    },
    dateToday() {
      let date = new Date();
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "short"
      })} ${date.getFullYear()}`;
    }
  },
  methods: {
    reSchedule() {
      let payload = {
        contract_id: this.contract.id,
        contract: this.contract,
        schedule_id: this.schedule.id,
        reason: this.postpone.reason,
        date: this.postpone.date,
        time: this.postpone.time,
        duration: "60"
      };
      console.log(payload);
      this.$store.dispatch("reSchedule", payload);
      this.closeActionPageMixin();
      this.$router.push("/");
    }
  },
  data() {
    return {
      postpone: {
        reason: "Client not available",
        date: "Collect",
        time: ""
      }
    };
  }
};
</script>
