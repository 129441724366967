async function refreshToken($axios, app) {
  await $axios.get('auth/refresh')
    .then((response) => {
      // console.log('Get Refresh Token');
      // console.log(response);
      if (response.status === 200) {
        // console.log('Setting Token.');
        app.store.commit('setExpiredDate');
        return app.$auth.setUserToken(response.data.token)
        .then(() => {
          // console.log('Set Token');
        })
        .catch((error) => {
          // console.log(error.response);
          return Promise.reject(error)
        });
      }
    });
}

function checkIsExpired() {
  const nowDate = new Date();
  const expired = window.localStorage.getItem('auth._expired_date');
  const expiredDate = new Date(expired);
  return (expired !== undefined && expired !== null && nowDate > expiredDate);
}

/**
 * Axios module configuration
 */
export default function ({$axios, redirect, context, app, store}){
  $axios.defaults.baseURL = process.env.baseUrl;

  // Axios Intercept Requests
  $axios.interceptors.request.use(async function (config) {
    var isExpired = checkIsExpired();
    // If not one of these specific pages that doesn't need a token, use method to get the current token or request a new one.  Otherwise, use current token (possibly none)
    if (isExpired &&
      !config.url.includes('login') &&
      !config.url.includes('refresh') &&
      !config.url.includes('forgot_password') &&
      !config.url.includes('reset_password') &&
      !config.url.includes('activate')) {
        // console.log('Refresh Token');
        await refreshToken($axios, app);
    }

    // config.headers['Authorization'] = app.$auth.getToken('local');
    return config
  }, function (error) {
    return Promise.reject(error);
  })

  $axios.onRequest(config => {
    // console.log('Making request to ' + config.url)
    store.commit('setAxiosLoading', true);
  });

  $axios.onResponse(config => {
    // console.log('Done request to ' + config.url)
    store.commit('setAxiosLoading', false);
  });

  $axios.onError(error => {
    
    let res = error.response;
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    )
    {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          store.commit('setAxiosError', error.response);
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    } else {
      store.commit('setAxiosError', error.response);
    }
  });
}
