<template>
  <div v-if="loading" class="col-12">
    <transition name="loader">
      <div  id="loader" class="text-center">
        <div class="loading">
            {{loadingTxt}} <img src="/img/loader.svg" alt="">
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
    props:['loadingTxt'],
    computed:{
      loading(){
        return this.$store.state.axios.loading
      }
    },
    mounted(){
      if(this.loadingTxt == ""){
        this.loadingTxt = "Checking for New Data"
      }
    },

    data() {
      return {
        loadingTest:true
      }
    },
}
</script>
