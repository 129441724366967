import { configure, extend } from 'vee-validate/dist/vee-validate.full';
import * as rules from 'vee-validate/dist/rules';

const config = {
  aria: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  },
  classes: true,
  delay: 0,
  dictionary: null,
  errorBagName: 'errors', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'fields',
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  inject: true,
  locale: 'en',
  validity: true,
  useConstraintAttrs: true
};
configure(config);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Min Length Validator.
extend('minLength', {
  validate (value, { length }) {
		return value.length >= length;
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} items must be selected.'
});

// Mobile Number
extend('mobileNo', {
  validate: value => {
    var regex = new RegExp(/((?:\+27|27)|0)(=\d{1}|31|72|81|82|73|83|74|84|85|86|87|88|89|76|61|60|62|63|70|71|72|73|74|78|79)\s?(\d{7})/); //('/^(\+?27|0)\s?\d{2}\s?\d{3}\s?\d{4}$/');
    return regex.test(value);
  },
  message: '{_field_} needs to be a valid South African mobile number.'
});

extend('password', {
  validate: value => {
    return /^[A-Za-z0-9\d=!\-@._*]*$/.test(value) // consists of only these
      && /[a-z]/.test(value) // has a lowercase letter
      && /\d/.test(value) // has a digit
  },
  message: '{_field_} needs to include uppercase and numbers in your password.'
});

// Rsa Id Numbr
extend('rsaID', {
  validate: value => {
    var regex = new RegExp(/(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/);
    return regex.test(value);
  },
  message: '{_field_} needs to be a valid South African ID Number.'
});
