<template>
    <nav v-if="employeeData" class="mainNav staffNav" :class="{'hide': !navVisible}">
      <router-link  :to="'/employees/' + employeeData.profile.id + '/requests'" tag="button" class="btn"><img style="width:2.5em; margin-right:-1em" class="" src="/icons/nav-inspections.svg" alt=""><small>Requests</small></router-link>
      <router-link  :to="'/employees/' + employeeData.profile.id + '/profile/documents'" tag="button" class="btn"><img style="width:1.5em" class="d-block mx-auto" src="/icons/nav-documents.svg" alt=""><small>Documents</small></router-link>
      <router-link  :to="'/employees/' + employeeData.profile.id + '/scan'" tag="button" class="btn scanNav"><img style="width:2.3em" src="/icons/nav-scan.svg" alt=""><small>Scan</small></router-link>
      <router-link  :to="'/employees/' + employeeData.profile.id + '/timesheets'" tag="button" class="btn"><img style="width:2em" src="/icons/nav-timesheets.svg" alt=""><small>Timesheets</small></router-link>
      <router-link  :to="'/employees/' + employeeData.profile.id + '/profile'" tag="button" class="btn"><img style="width:1.8em" src="/icons/nav-profile.svg" alt=""><small>Profile</small></router-link>
    </nav>
</template>

<script>
export default {
  computed: {
    navVisible(){
      if(this.$route.name == 'employees-employee_id-requests' || this.$route.name == 'employees-employee_id-timesheets'){
        return true
      }
      return false
    },
    employeeData(){
      return this.$store.state.auth.user
    }
  },
  methods: {
  }
}
</script>