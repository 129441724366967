import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'

export default ({ store }) => {
  window.onNuxtReady(() => {
    new VuexPersistence({
      storage: localforage,
      asyncStorage: true
    }).plugin(store);
  });
}
