import Vue from 'vue'
import { mapGetters } from 'vuex'

const User = {
  install (Vue, Options) {
    Vue.mixin({
      computed: {
        role: function () {
          if (this.checkUserPermission(['admin app access'])) {
            return 'admin';
          }
          if (this.checkUserPermission(['equipment manager'])) {
            return 'equipment manager';
          }
          if (this.checkUserPermission(['store manager'])) {
            return 'store manager';
          }
          if (this.isSectorManager) {
              return 'sector manager';
          }
          return 'none';
        },
        isSectorManager: function () {
          if(!this.loggedInUser) return false
          return (this.loggedInUser !== undefined
            && this.loggedInUser.employee !== undefined
            && this.loggedInUser.employee !== null
            && this.loggedInUser.employee.managers_sectors !== undefined
            && this.loggedInUser.employee.managers_sectors !== null
            && this.loggedInUser.employee.managers_sectors.length > 0
            );
        },
        ...mapGetters({
            loggedInUser: 'loggedInUser',
            isAuthenticated: 'isAuthenticated',
            isExpired: 'isExpired'
        }),
      },
      methods: {
        checkUserPermission(permArray) {
          var found = false;
          if (this.isAuthenticated === true
            && this.loggedInUser !== undefined
            && this.loggedInUser.permissions !== undefined
            && this.loggedInUser.permissions !== null
            && this.loggedInUser.permissions.length > 0) {
            var foundIndex = this.loggedInUser.permissions.findIndex((perm) => permArray.includes(perm.name));
            found = (foundIndex !== -1) ? true: false;
          }
          return found;
        },
        async checkUserPermissionRoute(permArray, logout = false) {
          var found = this.checkUserPermission(permArray);
          if (!found) {
            this.$alertify.alert('Permission Error', 'Sorry, but you do not have permission to access this route.');
            if (logout) {
              await this.$auth.logout();
            } else {
              this.$router.push({
                path: '/'
              });
            }
            return false;
          }
          return true;
        },
        async redirectBasedOnPermissionAndRole() {
          if ((this.role === 'admin' && this.isSectorManager) || (this.role === 'sector manager' && this.isSectorManager)) {
            this.$router.push({
              path: '/'
            });
          } else if (this.role === 'admin') {
              this.$router.push({
                path: '/contracts'
              });
          } else if (this.role === 'equipment manager') {
            this.$router.push({
              path: '/equipment'
            });
          } else if (this.role === 'store manager') {
            this.$router.push({
              path: '/orders'
            });
          } else {
            await this.$auth.logout();
          }
        }
      },
    })
  }
}

Vue.use(User);
