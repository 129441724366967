<template>
    <div>
           <versions></versions>
            <notifications></notifications>
        <nuxt />
    </div>
</template>

<script>
import Versions from '~/components/versions.vue'
import Notifications from '~/components/notifications.vue'
export default {
   components:{
       Versions,
       Notifications
   },
   created(){
      
   }
}
</script>