export const defaultState = () => ({
  version: process.env.version,
  apiVersion: '0.0.0',
  auth: {
    loggedIn: false,
    user: null,
    expired: null,
  },
  ui:{
    activeModel: '',
    activeModelData:null,
    bankList: [],
    docTypes: [],
  },
  contracts:[],
  contractDetail:{},
  contractDetailOrders: {},
  orders:[],
  ordersRate:{},
  orderDetail:{},
  orderTemplate: [],
  equipment:[],
  equipmentDetail:{},
  equipment_statuses: [],
  equipment_location_statuses: [],
  equipment_conditions: [],
  inspections:{
    list:[],
    sorted:[]
  },
  axios:{
    error: null,
    loading: false,
  },
  alertNotification: null,
  newInspectionTemplate:{},
  inspectionDetail: {},
  previousInspectionDetail: {},
  previousInspectionId: null,
  areaImages : [],
  cleaning: {
    room:null,
    checklist: null,
    logs: null
  },
  requests:[],
  request_types:[],
  stockItems:[],
  timesheets:[]
});
