<template>
    <div>
        <versions></versions>
        <notifications></notifications>
        <div>
            <Nuxt />
        </div>        
        <NavbarStaff></NavbarStaff>
    </div>
</template>

<script>
import Versions from '~/components/versions.vue'
import Notifications from '~/components/notifications.vue'
import NavbarStaff from "~/components/navbarStaff.vue";
export default {
    name:'EmployeeLayout',
    components:{
        Versions,
        Notifications,
        NavbarStaff
    },
    computed:{
        employeeData(){
            return this.$store.state.auth.user
        },
    },
    async created(){
        await this.$auth.fetchUser();
        // if(this.$store.state.mode != 'employee'){
        //     alertify.error('You are not logged in as an employee.')
        // } 
    }
}
</script>