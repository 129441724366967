export default function({ store, redirect }) {
    // console.log("USER ROLE: ",store.$auth.user.role)

    // switch(store.$auth.user.role){
    //     case 'contracts':
    //         redirect('/contracts')
    //         break;
    //     case 'orders':
    //         redirect('/orders')
    //         break;
    //     case 'equipment':
    //         redirect('/equipment')
    //         break;
    // }
    // console.log("USER: ",this.$auth.user) admin, sector manager, contracts, orders, equipment
}
