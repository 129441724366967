<template>
  <nav v-if="isAuthenticated" class="mainNav" :class="{'hide': !navVisible}">
    <router-link v-if="role == 'sector manager' || (role == 'admin' && isSectorManager)" to="/" tag="button" class="btn"><img src="/icons/nav-inspections.svg" alt=""><small>Inspections</small></router-link>
    <router-link v-if="role == 'admin' ||  role == 'sector manager'" to="/contracts" tag="button" class="btn"><img src="/icons/nav-contracts.svg" alt=""><small>Contracts</small></router-link>
    <router-link v-if="role == 'admin' ||  role == 'store manager'" to="/orders" tag="button" class="btn"><img src="/icons/nav-orders.svg" alt=""><small>Orders</small></router-link>
    <router-link v-if="role == 'admin' ||  role == 'equipment manager'" to="/equipment" tag="button" class="btn"><img src="/icons/nav-equipment.svg" alt=""><small>Equipment</small></router-link>
    <router-link to="/logout" tag="button" class="btn faded-20"><img src="/icons/nav-logout.svg" alt=""><small>Logout</small></router-link>
  </nav>
</template>


<script>
export default {
  computed: {
    navVisible(){
      if(this.$route.name == 'index' || this.$route.name == 'equipment' || this.$route.name == 'orders' || this.$route.name == 'contracts'){
        return true
      }
      return false
    }
    // role(){
    //   console.log(this.loggedInUser);
    //   if (this.checkUserPermission(['admin app access'])) {
    //     return 'admin'
    //   }
    // }
  },
  methods: {
    async logout(){
      await this.$auth.logout()
      location.reload()
    }
  }
}
</script>
