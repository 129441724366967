<template>
</template>

<script>
  export default {
    watch: {
      error: function (newErrors, oldErrors) {
        if (newErrors !== null) {
          this.checkErrors();
        }
      },
      alertNotification: function (newAlertNotification, oldAlertNotification) {
        if (newAlertNotification !== null && oldAlertNotification === null) {
          this.sendAlertNotification();
        }
      }

    },
    computed: {
      error: function () {
        return this.$store.getters['getError'];
      },
      errorCode: function () {
        return this.$store.getters['getErrorCode'];
      },
      alertNotification: function () {
        return this.$store.state.alertNotification;
      },
    },
    methods: {
      sendAlertNotification() {
        alertify.alert(this.alertNotification.title, this.alertNotification.message);
        this.$store.commit('setAlertNotification');
      },
      async checkErrors () {
        // console.error(this.error);
        if (this.errorCode !== null) {
          var message = null;
          if (this.error.data !== undefined && this.error.data.message) {
            message = this.error.data.message;
          }
          switch (this.errorCode) {
            case 401:
              if (message === 'Token has expired' || message === 'Token not provided') {
                // this.logOutMsg(message);
                await this.$auth.logout();
              }
              break;
            case 403:
              await this.unAuthorized(this.error);
              break;
            case 404:
              await this.notFound(this.error);
              break;
            case 422:
              await this.validation(this.error);
              break;
            case 500:
              if (message === 'The token has been blacklisted' || message === 'Token could not be parsed from the request.') {
                await this.$auth.logout();
              }
              break;
            default:
              await this.serverError(message);
              break;
          }
          await this.$store.commit('setAxiosError');
        }
      },
      unAuthorized (response) {
        var alertNotification = {
          title: 'Unauthorized',
          message: response.data.message
        };
        this.$store.commit('setAlertNotification', alertNotification);
      },
      notFound (response) {
        console.log(response);
        var alertNotification = {
          title: 'Not found',
          message: response.data.message
        };
        this.$store.commit('setAlertNotification', alertNotification);
        // alertify.alert('Not Found', message);
      },
      validation (response) {
        const { errors } = response.data;
        const title = '<h5>Validation Errors</h5><br>';
        var messages = '';

        for (var key in errors) {
          // skip loop if the property is from prototype
          if (!errors.hasOwnProperty(key)) continue;

          var el = errors[key];
          if (el.length > 0) {
            el.forEach(msg => {
              messages = messages + msg + '<br>';
            });
          }
        }
        if (messages !== '') {
          alertify.alert('Validation Error', title + messages);
        }
      },
      serverError (response) {
        alertify.alert('Server Error', 'Error, has occurred! Please contact your adminstrator for help.');
      },
      logOutMsg (message) {
        alertify.alert('Server Error', message);
      }
    },
    mounted: function() {
      // console.log('mounted alertify');
    },
  }
  </script>
