const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js');
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['onlineMode'] = require('../middleware/onlineMode.js');
middleware['onlineMode'] = middleware['onlineMode'].default || middleware['onlineMode']

middleware['pages'] = require('../middleware/pages.js');
middleware['pages'] = middleware['pages'].default || middleware['pages']

middleware['userRole'] = require('../middleware/userRole.js');
middleware['userRole'] = middleware['userRole'].default || middleware['userRole']

export default middleware
