<template>
  <main id="sign" class="screen">
      <header class="flat">
        <button @click="closeActionPageMixin()" class="btn"><img src="/icons/close.svg" alt=""></button>
        <button @click="clearSignature()" class="btn">CLEAR</button>
      </header>
      <section class="content" id="signatureCanvas">
        <canvas></canvas>
        <div id="sigCropper"></div>
      </section>
      <footer class="px-3">
        <button @click="saveSignature()" class="btn btn-block btn-primary btn-sm">CONFIRM</button>
      </footer>
  </main>
</template>

<script>
export default {
    head: {
      link: [
        { rel: 'stylesheet',  href: '/css/vendors/signature-pad.css' },
      ],
    },
    mounted(){
      this.initSignature();

      // window.addEventListener('resize', this.resizeCanvas)
      // window.addEventListener('deviceorientation', this.resizeCanvas)
    },
    beforeDestroy() {
      window.removeEventListener('resize',this.resizeCanvas)
      window.removeEventListener('deviceorientation',this.resizeCanvas)
    },
    methods:{
      initSignature: function () {
        var signatureCanvas = document.querySelector("#signatureCanvas canvas");
        this.ui.signaturePad = new SignaturePad(signatureCanvas, {
          minWidth: 1,
          maxWidth: 2
        });

        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        signatureCanvas.width = signatureCanvas.offsetWidth * ratio;
        signatureCanvas.height = signatureCanvas.offsetHeight * ratio;
        signatureCanvas.getContext("2d").scale(ratio, ratio);
        this.ui.signaturePad.clear();

      },

      resizeCanvas() {
          console.log('RESIZE')
          var signatureCanvas = document.querySelector("#signatureCanvas canvas");
          var ratio =  Math.max(window.devicePixelRatio || 1, 1);

          signatureCanvas.width = signatureCanvas.offsetWidth * ratio;
          signatureCanvas.height = signatureCanvas.offsetHeight * ratio;
          signatureCanvas.getContext("2d").scale(ratio, ratio);

          this.ui.signaturePad.clear();
      },

      clearSignature: function () {
        this.ui.signaturePad.clear();
      },

      saveSignature: function () {
        // var image = new Image();
        // image.src = this.ui.signaturePad.toDataURL("image/png");
        // console.log(image, 'image');
        // return image;
        var imgData = this.ui.signaturePad.toDataURL("image/png");
        console.log('Save Signature');
        // console.log('CROPPED',svg.outerHTML,base64)
        this.$nuxt.$emit('saveSignature',imgData)

      },



    },
    data() {
      return {
        ui: {
          signaturePad:null,
        }
      }
    },
}
</script>
