export default function ({ store, redirect }) {
  // If the user is not authenticated
  if (!store.state.auth.loggedIn) {
    // console.log('Store', store);
    window.localStorage.clear();
    redirect('/login');
  }

  // console.log("USER ROLE: ",store.$auth.user.role)
  // switch(store.$auth.user.role){
  //   case 'contracts':
  //     redirect('/contracts')
  //     break;
  //   case 'orders':
  //     redirect('/orders')
  //     break;
  //   case 'equipment':
  //     redirect('/equipment')
  //     break;
  // }
}
