export default {
  currentSectors(state){
    // let sectorObj = {}
    // state.contracts.forEach((contract) => {
    //   if(contract.sector_id in sectorObj) return
    //   sectorObj[contract.sector_id] = contract.sector
    // });
    // return sectorObj

    let sectorArr = []
    state.contracts.forEach(contract => {
      if(sectorArr.some(sec => sec.id == contract.sector_id)) return
      sectorArr.push({id: contract.sector_id, sector: contract.sector})
    });
    return sectorArr
  },
  isAuthenticated (state) {
    return state.auth.loggedIn
  },
  loggedInUser (state) {
    return state.auth.user
  },
  isExpired (state) {
    const nowDate = new Date;
    return (state.auth.expired === null || nowDate > state.auth.expired);
  },
  getError(state) {
    return state.axios.error;
  },
  getErrorCode(state) {
    if (state.axios.error !== null) {
      return parseInt(state.axios.error.status);
    }
    return null;
  },
  getVersion(state) {
    return process.env.version;
  },
  getTasksAnswered(state){
    if(!state.cleaning.checklist) return false
    let tasks = JSON.parse(JSON.stringify(state.cleaning.checklist.tasks_answered))
    return tasks
  }

  // EMPLOYEES


}
