<template>
  <main id="scan" class="screen">
      <header class="flat">
        <h1>
          Scan to Verify
        </h1>
        <button @click="closeActionPageMixin()" class="btn"><img src="/icons/close.svg" alt=""></button>
      </header>
      <section class="content flexed justify-content-center pb-5">
        <img class="target" src="/icons/scan-marker.svg" alt="">

        <no-ssr>
          <vue-qr-reader v-on:code-scanned="codeArrived" :camera="cameraFace" line-color="#49c18e" :video-width="camWidth" :video-height="camHeight"/>
        </no-ssr>

      </section>
  </main>
</template>

<script>
import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd.js';
export default {
    components: {
        VueQrReader
    },
    computed:{
      contractList() {
        return this.$store.state.contracts
      },
      equipmentList() {
        return this.$store.state.equipment;
      },
      contract(){
        return this.$store.state.contractDetail
      },
    },
    mounted(){
      this.camWidth = window.innerWidth
      this.camHeight = window.innerHeight
    },
    methods:{
      checkEcNumber(ecNum) {
        let found = false;
        if (this.equipmentList !== undefined && this.equipmentList.length > 0) {
          let index = this.equipmentList.findIndex((equipment) => equipment.ec_number == ecNum);
          if (index > -1) {
            found = true;
          }
        } else if (this.contract !== undefined && this.contract.equipment !== undefined && this.contract.equipment.length > 0) {
          let index = this.contract.equipment.findIndex((equipment) => equipment.ec_number == ecNum);
          if (index > -1) {
            found = true;
          }
        }
        return found;
      },
      codeArrived (code) {
        var url = process.env.appUrl + '/e/';
        console.log('App Url:', url);
        var ec_num = code.replace(url, '');
        console.log('ec_num ', ec_num);
        console.log('SCANNED ', code, ec_num);
        if(this.checkEcNumber(ec_num)){
          alertify.notify('QR Scanned Successfully','success')
          this.closeActionPageMixin()
          this.$router.push('/equipment/'+ec_num+'?verify');

        }else{
          this.closeActionPageMixin()
          alertify.notify('QR Error - Qr code cannot be found. Please try again','error')
        }

      }
    },
    data() {
      return {
        cameraFace:"rear",
        camWidth:600,
        camHeight:667
      }
    },
}
</script>
