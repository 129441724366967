import Vue from 'vue';

import { defaultState } from './defaultState.js';
import ImportGetters from './getters.js';
import ImportMutations from './mutations.js';
import ImportActions from './actions.js';

// initial state
export const state = () => defaultState();
export const getters = ImportGetters
export const mutations = ImportMutations
export const actions = ImportActions